import React from 'react';

export default function Datenschutzerklarung() {
  return (
    <div className="datenschutz-container"> {/* Keep only the div */}
      <h4 className="heading-4 mb-[40px] text-grape md:pt-[40px]">
        Datenschutz­erklärung

      </h4>
      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        1. Datenschutz auf einen Blick
      </h4>
      <p className="heading-5 mb-[30px] text-cool-grey">Allgemeine Hinweise</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>

      <p className="heading-5 mb-[30px] text-cool-grey">
        Datenerfassung auf dieser Website
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
        zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wie erfassen wir Ihre Daten?
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie diese Website betreten.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wofür nutzen wir Ihre Daten?
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Welche Rechte haben Sie bezüglich Ihrer Daten?
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit an uns wenden.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Analyse-Tools und Tools von Dritt­anbietern
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit sogenannten
        Analyseprogrammen.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
        folgenden Datenschutzerklärung.
      </p>
      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        2. Hosting
      </h4>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Amazon Web Services (AWS)
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F.
        Kennedy, 1855 Luxemburg (nachfolgend AWS).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten
        auf den Servern von AWS verarbeitet. Hierbei können auch
        personenbezogene Daten an das Mutterunternehmen von AWS in die USA
        übermittelt werden. Die Datenübertragung in die USA wird auf die
        EU-Standardvertragsklauseln gestützt. Details finden Sie hier:
        <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/">
          https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:{' '}
        <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr">
          https://aws.amazon.com/de/privacy/?nc1=f_pr.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst
        zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
        Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
        </a>
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">Auftragsverarbeitung</p>

      <p className="paragraph mb-[30px] text-cool-grey">
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des
        oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        3. Allgemeine Hinweise und Pflicht­informationen
      </h4>
      <p className="heading-5 mb-[30px] text-cool-grey">Datenschutz</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Hinweis zur verantwortlichen Stelle
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p className="paragraph  text-cool-grey">Gogaudi GmbH</p>
      <p className="paragraph  text-cool-grey">Tal 44</p>
      <p className="paragraph mb-[30px] text-cool-grey">80331 München</p>
      <p className="paragraph text-cool-grey">Telefon: +49 1771 671 702</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        E-Mail: info@gogaudi.de
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">Speicherdauer</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
        dieser Website
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir
        Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
        nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
        ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten
        in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von
        Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
        oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via
        Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
        zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist
        jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
        Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir
        Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
        verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen
        Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c
        DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
        berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über
        die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den
        folgenden Absätzen dieser Datenschutzerklärung informiert.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Empfänger von personenbezogenen Daten
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen
        externen Stellen zusammen. Dabei ist teilweise auch eine Übermittlung
        von personenbezogenen Daten an diese externen Stellen erforderlich. Wir
        geben personenbezogene Daten nur dann an externe Stellen weiter, wenn
        dies im Rahmen einer Vertragserfüllung erforderlich ist, wenn wir
        gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten an
        Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1
        lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige
        Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
        Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur
        auf Grundlage eines gültigen Vertrags über Auftragsverarbeitung weiter.
        Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über gemeinsame
        Verarbeitung geschlossen.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Beschwerde­recht bei der zuständigen Aufsichts­behörde
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Recht auf Daten­übertrag­barkeit
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Auskunft, Berichtigung und Löschung
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit an uns wenden.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">
        Recht auf Einschränkung der Verarbeitung
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen:
      </p>
      <p className="paragraph text-cool-grey">
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
        Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
        überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </p>
      <p className="paragraph text-cool-grey">
        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
        geschah/geschieht, können Sie statt der Löschung die Einschränkung der
        Datenverarbeitung verlangen.
      </p>
      <p className="paragraph text-cool-grey">
        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
        jedoch zur Ausübung, Verteidigung oder Geltendmachung von
        Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </p>
      <p className="paragraph text-cool-grey">
        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
        muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
        werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
        haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        SSL- bzw. TLS-Verschlüsselung
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        4. Datenerfassung auf dieser Website
      </h4>
      <p className="heading-5 mb-[30px] text-cool-grey">Cookies</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen
        stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die
        Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb
        von Webseiten (z. B. Cookies zur Abwicklung von
        Zahlungsdienstleistungen).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Webseitenfunktionen ohne diese nicht
        funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
        Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder
        zu Werbezwecken verwendet werden.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs,
        zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B.
        für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
        Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
        Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
        notwendigen Cookies zur technisch fehlerfreien und optimierten
        Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung
        von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt
        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Welche Cookies und Dienste auf dieser Website eingesetzt werden, können
        Sie dieser Datenschutzerklärung entnehmen.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Einwilligung mit Usercentrics
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre
        Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder
        zum Einsatz bestimmter Technologien einzuholen und diese
        datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist die
        Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Website:{' '}
        <a href="https://usercentrics.com/de/">https://usercentrics.com/de/</a>
        (im Folgenden „Usercentrics“).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten
        an Usercentrics übertragen:
      </p>
      <p className="paragraph text-cool-grey">
        Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)
      </p>
      <p className="paragraph text-cool-grey">Ihre IP-Adresse</p>
      <p className="paragraph text-cool-grey">
        Informationen über Ihren Browser
      </p>
      <p className="paragraph text-cool-grey">
        Informationen über Ihr Endgerät
      </p>
      <p className="paragraph text-cool-grey">
        Zeitpunkt Ihres Besuchs auf der Website
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um
        Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu
        können. Die so erfassten Daten werden gespeichert, bis Sie uns zur
        Löschung auffordern, das Usercentrics-Cookie selbst löschen oder der
        Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche
        Aufbewahrungspflichten bleiben unberührt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Usercentrics-Banner auf dieser Website wurde mit Hilfe von eRecht24
        konfiguriert. Das erkennen Sie daran, dass im Banner das Logo von
        eRecht24 auftaucht. Um das eRecht24-Logo im Banner auszuspielen, wird
        eine Verbindung zum Bildserver von eRecht24 hergestellt. Hierbei wird
        auch die IP-Adresse übertragen, die jedoch nur in anonymisierter Form in
        den Server-Logs gespeichert wird. Der Bildserver von eRecht24 befindet
        sich in Deutschland bei einem deutschen Anbieter. Das Banner selbst wird
        ausschließlich von Usercentrics zur Verfügung gestellt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen
        Einwilligungen für den Einsatz bestimmter Technologien einzuholen.
        Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">Auftragsverarbeitung</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des
        oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">Kontaktformular</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Anfrage per E-Mail, Telefon oder Telefax
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">Hubspot CRM</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot Inc. 25
        Street, Cambridge, MA 02141 USA (nachfolgend Hubspot CRM).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Hubspot CRM ermöglicht es uns unter anderem, bestehende und potenzielle
        Kunden sowie Kundenkontakte zu verwalten. Mit Hilfe von Hubspot CRM sind
        wir in der Lage, Kundeninteraktionen per E-Mail, Social Media oder
        Telefon über verschiedene Kanäle hinweg zu erfassen, zu sortieren und zu
        analysieren. Die so erfassten personenbezogenen Daten können ausgewertet
        und für die Kommunikation mit dem potenziellen Kunden oder für
        Marketingmaßnahmen (z. B. Newslettermailings) verwendet werden. Mit
        Hubspot CRM sind wir ferner in der Lage, das Nutzerverhalten unserer
        Kontakte auf unserer Website zu erfassen und zu analysieren.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
        einer möglichst effizienten Kundenverwaltung und Kundenkommunikation.
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
        und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
        Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z.
        B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Details entnehmen Sie der Datenschutzerklärung von Hubspot:{' '}
        <a href="https://legal.hubspot.com/de/privacy-policy">
          https://legal.hubspot.com/de/privacy-policy.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:{' '}
        <a href="https://www.hubspot.de/data-privacy/privacy-shield">
          https://www.hubspot.de/data-privacy/privacy-shield.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TN8pAAG&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TN8pAAG&status=Active
        </a>
      </p>
      <p className="heading-5 mb-[30px] text-cool-grey">Auftragsverarbeitung</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des
        oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        5. Analyse-Tools und Werbung
      </h4>
      <p className="paragraph mb-[30px] text-cool-grey">Google Analytics</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
        Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Irland.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
        Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
        verschiedene Nutzungsdaten, wie z. B. Seitenaufrufe, Verweildauer,
        verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden
        in einer User-ID zusammengefasst und dem jeweiligen Endgerät des
        Websitebesuchers zugeordnet.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Des Weiteren können wir mit Google Analytics u. a. Ihre Maus- und
        Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google
        Analytics verschiedene Modellierungsansätze, um die erfassten Datensätze
        zu ergänzen und setzt Machine-Learning-Technologien bei der Datenanalyse
        ein.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Google Analytics verwendet Technologien, die die Wiedererkennung des
        Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B.
        Cookies oder Device-Fingerprinting). Die von Google erfassten
        Informationen über die Benutzung dieser Website werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:{' '}
        <a href="https://privacy.google.com/businesses/controllerterms/mccs/">
          https://privacy.google.com/businesses/controllerterms/mccs/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">Browser Plugin</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
        verhindern, indem Sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerklärung von Google:{' '}
        <a href="https://support.google.com/analytics/answer/6004245?hl=de">
          https://support.google.com/analytics/answer/6004245?hl=de.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">Auftragsverarbeitung</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
        abgeschlossen und setzen die strengen Vorgaben der deutschen
        Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">Google Ads</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
        Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
        House, Barrow Street, Dublin 4, Irland.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Google Ads ermöglicht es uns Werbeanzeigen in der Google-Suchmaschine
        oder auf Drittwebseiten auszuspielen, wenn der Nutzer bestimmte
        Suchbegriffe bei Google eingibt (Keyword-Targeting). Ferner können
        zielgerichtete Werbeanzeigen anhand der bei Google vorhandenen
        Nutzerdaten (z. B. Standortdaten und Interessen) ausgespielt werden
        (Zielgruppen-Targeting). Wir als Websitebetreiber können diese Daten
        quantitativ auswerten, indem wir beispielsweise analysieren, welche
        Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt haben und wie
        viele Anzeigen zu entsprechenden Klicks geführt haben.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:
        <a href="https://policies.google.com/privacy/frameworks">
          https://policies.google.com/privacy/frameworks
        </a>{' '}
        und
        <a href="https://privacy.google.com/businesses/controllerterms/mccs/">
          https://privacy.google.com/businesses/controllerterms/mccs/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Google Ads Remarketing
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Diese Website nutzt die Funktionen von Google Ads Remarketing. Anbieter
        ist die Google Ireland Limited („Google“), Gordon House, Barrow Street,
        Dublin 4, Irland.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Mit Google Ads Remarketing können wir Personen, die mit unserem
        Online-Angebot interagieren bestimmten Zielgruppen zuordnen, um ihnen
        anschließend interessenbezogene Werbung im Google-Werbenetzwerk anzeigen
        zu lassen (Remarketing bzw. Retargeting).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Des Weiteren können die mit Google Ads Remarketing erstellten
        Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von Google
        verknüpft werden. Auf diese Weise können interessenbezogene,
        personalisierte Werbebotschaften, die in Abhängigkeit Ihres früheren
        Nutzungs- und Surfverhaltens auf einem Endgerät (z. B. Handy) an Sie
        angepasst wurden auch auf einem anderen Ihrer Endgeräte (z. B. Tablet
        oder PC) angezeigt werden.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie über einen Google-Account verfügen, können Sie der
        personalisierten Werbung unter folgendem Link widersprechen:{' '}
        <a href="https://www.google.com/settings/ads/onweb/">
          https://www.google.com/settings/ads/onweb/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Weitergehende Informationen und die Datenschutzbestimmungen finden Sie
        in der Datenschutzerklärung von Google unter:{' '}
        <a href="https://policies.google.com/technologies/ads?hl=de">
          https://policies.google.com/technologies/ads?hl=de.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Zielgruppenbildung mit Kundenabgleich
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Zur Zielgruppenbildung verwenden wir unter anderem den Kundenabgleich
        von Google Ads Remarketing. Hierbei übergeben wir bestimmte Kundendaten
        (z. B. E-Mail-Adressen) aus unseren Kundenlisten an Google. Sind die
        betreffenden Kunden Google-Nutzer und in ihrem Google-Konto eingeloggt,
        werden ihnen passende Werbebotschaften innerhalb des Google-Netzwerks
        (z. B. bei YouTube, Gmail oder in der Suchmaschine) angezeigt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Meta-Pixel (ehemals Facebook Pixel)
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von
        Facebook/Meta. Anbieter dieses Dienstes ist die Meta Platforms Ireland
        Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
        werden nach Aussage von Facebook jedoch auch in die USA und in andere
        Drittländer übertragen.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem
        diese durch Klick auf eine Facebook-Werbeanzeige auf die Website des
        Anbieters weitergeleitet wurden. Dadurch können die Wirksamkeit der
        Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
        ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die erhobenen Daten sind für uns als Betreiber dieser Website anonym,
        wir können keine Rückschlüsse auf die Identität der Nutzer ziehen. Die
        Daten werden aber von Facebook gespeichert und verarbeitet, sodass eine
        Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook die
        Daten für eigene Werbezwecke, entsprechend der
        Facebook-Datenverwendungsrichtlinie (
        <a href="https://de-de.facebook.com/about/privacy/">
          https://de-de.facebook.com/about/privacy/
        </a>
        ) verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen
        auf Seiten von Facebook sowie außerhalb von Facebook ermöglichen. Diese
        Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst
        werden.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir nutzen die Funktion des erweiterten Abgleichs innerhalb der
        Meta-Pixel.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Der erweiterte Abgleich ermöglicht uns, verschiedene Arten von Daten (z.
        B. Wohnort, Bundesland, Postleitzahl, gehashte E-Mail-Adressen, Namen,
        Geschlecht, Geburtsdatum oder Telefonnummer) unserer Kunden und
        Interessenten, die wir über unsere Website sammeln an Meta (Facebook) zu
        übermitteln. Durch diese Aktivierung können wir unsere Werbekampagnen
        auf Facebook noch präziser auf Personen zuschneiden, die sich für unsere
        Angebote interessieren. Außerdem verbessert der erweiterte Abgleich
        Zuordnung von Webseiten-Conversions und erweitert Custom Audiences.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf
        unserer Website erfasst und an Facebook weitergeleitet werden, sind wir
        und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand
        Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung
        verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit
        beschränkt sich dabei ausschließlich auf die Erfassung der Daten und
        deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende
        Verarbeitung durch Facebook ist nicht Teil der gemeinsamen
        Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in
        einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den
        Wortlaut der Vereinbarung finden Sie unter:{' '}
        <a href="https://www.facebook.com/legal/controller_addendum">
          https://www.facebook.com/legal/controller_addendum.
        </a>
        Laut dieser Vereinbarung sind wir für die Erteilung der
        Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
        datenschutzrechtlich sichere Implementierung des Tools auf unserer
        Website verantwortlich. Für die Datensicherheit der Facebook-Produkte
        ist Facebook verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen)
        hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei
        Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend
        machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:{' '}
        <a href="https://www.facebook.com/legal/EU_data_transfer_addendum">
          https://www.facebook.com/legal/EU_data_transfer_addendum
        </a>
        und{' '}
        <a href="https://de-de.facebook.com/help/566994660333381">
          https://de-de.facebook.com/help/566994660333381.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum
        Schutz Ihrer Privatsphäre:{' '}
        <a href="https://de-de.facebook.com/about/privacy/">
          https://de-de.facebook.com/about/privacy/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Sie können außerdem die Remarketing-Funktion „Custom Audiences” im
        Bereich Einstellungen für Werbeanzeigen unter{' '}
        <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">
          https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
        </a>
        deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
        Werbung von Facebook auf der Website der European Interactive Digital
        Advertising Alliance deaktivieren:
        <a href="http://www.youronlinechoices.com/de/praferenzmanagement/">
          http://www.youronlinechoices.com/de/praferenzmanagement/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Facebook Custom Audiences
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir nutzen Facebook Custom Audiences. Anbieter dieses Dienstes ist die
        Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie unsere Webseiten und Apps besuchen bzw. nutzen, unsere
        kostenlosen oder kostenpflichtigen Angebote in Anspruch nehmen, Daten an
        uns übermitteln oder mit den Facebook-Inhalten unseres Unternehmens
        interagieren, erfassen wir hierbei Ihre personenbezogenen Daten. Sofern
        Sie uns eine Einwilligung zur Nutzung von Facebook Custom Audiences
        erteilen, werden wir diese Daten an Facebook übermitteln, mit denen
        Facebook Ihnen passende Werbung ausspielen kann. Des Weiteren können mit
        Ihren Daten Zielgruppen definiert werden (Lookalike Audiences).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Facebook verarbeitet diese Daten als unser Auftragsverarbeiter. Details
        sind der Nutzungsvereinbarung von Facebook zu entnehmen:
        <a href="https://www.facebook.com/legal/terms/customaudience">
          https://www.facebook.com/legal/terms/customaudience.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung
        ist jederzeit widerrufbar.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:{' '}
        <a href="https://www.facebook.com/legal/terms/customaudience">
          https://www.facebook.com/legal/terms/customaudience
        </a>
        und{' '}
        <a href="https://www.facebook.com/legal/terms/dataprocessing">
          https://www.facebook.com/legal/terms/dataprocessing.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, der die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
        </a>
      </p>
      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        6. Newsletter
      </h4>
      <p className="paragraph mb-[30px] text-cool-grey">Newsletter­daten</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
        benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche
        uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
        E-Mail-Adresse und mit dem Empfang des Newsletters einverstanden sind.
        Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Für
        die Abwicklung der Newsletter nutzen wir Newsletterdiensteanbieter, die
        nachfolgend beschrieben werden.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">Mailchimp</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Diese Website nutzt die Dienste von Mailchimp für den Versand von
        Newslettern. Anbieter ist die Rocket Science Group LLC, 675 Ponce De
        Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Mailchimp ist ein Dienst, mit dem u.a. der Versand von Newslettern
        organisiert und analysiert werden kann. Wenn Sie Daten zum Zwecke des
        Newsletterbezugs eingeben (z. B. E-Mail-Adresse), werden diese auf den
        Servern von Mailchimp in den USA gespeichert.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">
        Mit Hilfe von Mailchimp können wir unsere Newsletterkampagnen
        analysieren. Wenn Sie eine mit Mailchimp versandte E-Mail öffnen,
        verbindet sich eine in der E-Mail enthaltene Datei (sog. web-beacon) mit
        den Servern von Mailchimp in den USA. So kann festgestellt werden, ob
        eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt
        wurden. Außerdem werden technische Informationen erfasst (z. B.
        Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Diese
        Informationen können nicht dem jeweiligen Newsletter-Empfänger
        zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse
        von Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt
        werden, um künftige Newsletter besser an die Interessen der Empfänger
        anzupassen.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wenn Sie keine Analyse durch Mailchimp wollen, müssen Sie den Newsletter
        abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen
        entsprechenden Link zur Verfügung.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6
        Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
        widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der
        bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
        unberührt.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
        Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns
        bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung
        des Newsletters aus der Newsletterverteilerliste gelöscht. Daten, die zu
        anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:{' '}
        <a href="https://mailchimp.com/eu-us-data-transfer-statement/">
          https://mailchimp.com/eu-us-data-transfer-statement/
        </a>
        und
        <a href="https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses">
          https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
        E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
        Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings
        erforderlich ist. Die Daten aus der Blacklist werden nur für diesen
        Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient
        sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der
        gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
        Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in
        der Blacklist ist zeitlich nicht befristet. Sie können der Speicherung
        widersprechen, sofern Ihre Interessen unser berechtigtes Interesse
        überwiegen.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">
        Näheres entnehmen Sie den Datenschutzbestimmungen von Mailchimp unter:{' '}
        <a href="https://mailchimp.com/legal/terms/">
          https://mailchimp.com/legal/terms/.
        </a>
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data
        Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der
        Europäischen Union und den USA, das die Einhaltung europäischer
        Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich,
        diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>
      </p>

      <h4 className="heading-4 mb-[40px] text-cool-grey md:pt-[40px]">
        8. Online-Marketing und Partner­programme
      </h4>

      <p className="paragraph mb-[30px] text-cool-grey">
        Affiliate-Programme auf dieser Website
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir nehmen an Affiliate-Partnerprogrammen teil. Bei Affiliate-Partner-Programmen werden Werbeanzeigen eines Unternehmens
        (Advertiser) auf Webseiten von anderen Unternehmen des Affiliate-Partner-Netzwerks (Publisher) platziert. Wenn Sie auf eine
        dieser Affiliate-Werbeanzeigen klicken, werden Sie zum beworbenen Angebot weitergeleitet. Sollten Sie anschließend eine
        bestimmte Transaktion (Conversion) tätigen, erhält der Publisher hierfür eine Vergütung. Zur Berechnung dieser Vergütung ist
        es erforderlich, dass der Affiliate-Netzwerkbetreiber nachvollziehen kann, über welche Werbeanzeige Sie auf das jeweilige
        Angebot gekommen sind und die vordefinierte Transaktion vorgenommen haben. Hierfür werden Cookies oder vergleichbare
        Wiedererkennungstechnologien (z. B. Device-Fingerprinting) eingesetzt.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der korrekten Berechnung seiner Affiliate-Vergütung. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">Wir nehmen an folgenden Affiliate-Programmen teil:</p>

      <p className="paragraph mb-[30px] text-cool-grey">Amazon Partner­programm</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Anbieter ist die Amazon Europe Core S.à.r.l. Details entnehmen Sie der Datenschutzerklärung von Amazon unter:{' '}
        <a href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010">
          https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010
        </a>.
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Weitere Informationen hierzu
        erhalten Sie vom Anbieter unter folgendem Link:{' '}
        <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active">
          https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
        </a>.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">AWIN</p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Betreiber des Affiliate-Netzwerks ist die AWIN AG, Eichhornstraße 3, 10785 Berlin (nachfolgend „AWIN“).
      </p>
      <p className="paragraph mb-[30px] text-cool-grey">
        AWIN und der Publisher sind gemeinsam für die Datenverarbeitung im Zusammenhang mit dem Partnerprogramm verantwortlich. Die
        ihnen gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Laut
        dieser Vereinbarung können Sie sich mit Ihren datenschutzrechtlichen Anliegen an beide Verantwortlichen wenden. Der jeweils
        zuerst angesprochene Verantwortliche wird Ihre Anfrage beantworten. Jeder Verantwortliche hält eigenständig
        Datenschutzinformationen nach Art. 13, 14 und 26 DSGVO vor und trifft die erforderlichen Maßnahmen zum Schutz personenbezogener
        Daten und zur Einhaltung der übrigen DSGVO-Vorschriften in seinem Unternehmen. Der Vertrag über gemeinsame Verarbeitung ist in
        den AGB von AWIN unter folgendem Link abrufbar:{' '}
        <a href="https://s3.amazonaws.com/docs.awin.com/Legal/Publisher+Terms/2020/DE+Publisher+Terms+GDPR+Annex.pdf">
          https://s3.amazonaws.com/docs.awin.com/Legal/Publisher+Terms/2020/DE+Publisher+Terms+GDPR+Annex.pdf
        </a>.
      </p>

      <p className="paragraph mb-[30px] text-cool-grey">Sonstige Affiliate-Partner-Programme</p>
      <p className="paragraph mb-[30px] text-cool-grey">Webgains</p>
      <p className="paragraph mb-[30px] text-cool-grey">Easy Marketing</p>
      <p className="paragraph mb-[30px] text-cool-grey">retailAds</p>
      <p className="paragraph mb-[30px] text-cool-grey">Tradedoubler</p>
      <p className="paragraph mb-[30px] text-cool-grey">Adcell</p>
      <p className="paragraph mb-[30px] text-cool-grey">Adtraction</p>
      <p className="paragraph mb-[30px] text-cool-grey">Partnerize</p>
      <p className="paragraph mb-[30px] text-cool-grey">Admitad</p>
      <p className="paragraph mb-[30px] text-cool-grey">TradeTracker</p>
      <p className="paragraph mb-[30px] text-cool-grey">Digistore24</p>
      <p className="paragraph mb-[30px] text-cool-grey">Adservice</p>
      <p className="paragraph mb-[30px] text-cool-grey">Yieldkit</p>
      <p className="paragraph mb-[30px] text-cool-grey">SuperClix</p>
      <p className="paragraph mb-[30px] text-cool-grey">UpPromote</p>
      
      </div>
  );
}

