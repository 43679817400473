import React from 'react';
import { Helmet } from 'react-helmet';
import '../index.css'; // Ensure this path points to your CSS file

function About() {
  return (
    <div className="about-container">
      <Helmet>
        <title>Über Uns - DeliktRadar</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über die Mission und Vision von DeliktRadar, einer Plattform zur Aufklärung und Prävention sexueller Gewalt in Deutschland."
        />
      </Helmet>

      <h1>Über DeliktRadar</h1>

      <p>
        <a href="https://deliktradar.de/" target="_blank" rel="noopener noreferrer" className="bold-link">DeliktRadar</a> ist eine Plattform, die Menschen dabei unterstützt, sich vor sexueller Gewalt zu schützen. Unser Ziel ist es, aufzuklären, Prävention zu fördern und den Opfern eine Stimme zu geben. Sexualisierte Gewalt ist ein ernstes gesellschaftliches Problem, und wir möchten Menschen helfen, ihre Rechte und die verfügbaren Hilfsangebote zu kennen.
      </p>

      <p>
        Die Idee zu <a href="https://deliktradar.de/" target="_blank" rel="noopener noreferrer" className="bold-link">DeliktRadar</a> entstand aus der Notwendigkeit, mehr Transparenz für sexuellee Gewalt zu schaffen. Unsere Plattform bietet Informationen über Präventionsmaßnahmen, rechtliche Möglichkeiten und lokale Unterstützungsnetzwerke, um sowohl Betroffenen als auch ihren Angehörigen zu helfen.
      </p>

      <p>
        Mit <a href="https://deliktradar.de/" target="_blank" rel="noopener noreferrer" className="bold-link">DeliktRadar</a> möchten wir auch ein Bewusstsein dafür schaffen, wie verbreitet sexualisierte Gewalt ist und wie wichtig es ist, ein unterstützendes Umfeld für die Opfer zu schaffen.
      </p>

     

      <p>&nbsp;</p> {/* Empty paragraph for spacing */}
      <p>&nbsp;</p> {/* Empty paragraph for spacing */}
    </div>
  );
}

export default About;
