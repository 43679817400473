// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import './index.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <Router>
      <div className="app">
        <header className="header">
          <nav>
            <div className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>

            <ul className={`menu ${menuOpen ? 'open' : ''}`}>
              <li><Link to="/" onClick={() => setMenuOpen(false)}>Start</Link></li>
              <li><Link to="/about" onClick={() => setMenuOpen(false)}>Über Uns</Link></li>
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
        </Routes>

        <footer className="footer">
          <nav>
            <ul className="footer-links">
              <li><Link to="/impressum" className="impressum-link">Impressum</Link></li>
              <li><Link to="/datenschutz" className="datenschutz-link">Datenschutzerklärung</Link></li>
            </ul>
            <p>© 2024 DeliktRadar</p>
          </nav>
        </footer>
      </div>
    </Router>
  );
}

export default App;
