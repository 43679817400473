import React from 'react';
import { Helmet } from 'react-helmet';

const Impressum = () => {
  return (
    <div className="datenschutz-container"> {/* Add a container class for styling if needed */}
      <Helmet>
        <title>Impressum - Enkel-Trick.de</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über das Impressum und die rechtlichen Informationen zu Enkel-Trick.de, einer Initiative von GoGaudi GmbH."
        />
      </Helmet>
      
      <h4 className="heading-4 mb-[40px] text-grape md:pt-[40px]">Impressum</h4>
      
      <p className="paragraph mb-[30px] text-cool-grey">
        Wie bereits eingangs erwähnt, ist Enkel-Trick.de ein kostenloses Angebot von GoGaudi, der Plattform für ein aktives Leben, mit hilfreichen Inhalten rund um das Thema Rente und gesundes Altern.
      </p>

      <p className="paragraph text-cool-grey">Angaben gemäß § 5 TMG</p>
      <p className="paragraph text-cool-grey">Gogaudi GmbH</p>
      <p className="paragraph text-cool-grey">Mercedes Benz Tower</p>
      <p className="paragraph text-cool-grey">Arnulfstraße 61</p>
      <p className="paragraph mb-[30px] text-cool-grey">80636 München</p>
      
      <p className="paragraph text-cool-grey"><strong>Registergericht:</strong></p>
      <p className="paragraph mb-[30px] text-cool-grey">Amtsgericht München</p>
      
      <p className="paragraph text-cool-grey"><strong>HRB:</strong></p>
      <p className="paragraph mb-[30px] text-cool-grey">294410</p>
      
      <p className="paragraph text-cool-grey"><strong>Vertreten durch:</strong></p>
      <p className="paragraph mb-[30px] text-cool-grey">Marcel Gerlinger</p>
      
      <p className="paragraph text-cool-grey"><strong>Kontakt:</strong></p>
      <p className="paragraph text-cool-grey">Telefon: +49 1771 671 702</p>
      <p className="paragraph mb-[30px] text-cool-grey">E-Mail: info@gogaudi.de</p>
      
      <p className="paragraph text-cool-grey"><strong>Umsatzsteuer-ID:</strong></p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
      </p>
      
      <p className="paragraph text-cool-grey"><strong>EU-Streitschlichtung:</strong></p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit: 
        <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.
      </p>
      <p className="paragraph text-cool-grey">
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>
      
      <p className="paragraph text-cool-grey"><strong>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle:</strong></p>
      <p className="paragraph mb-[30px] text-cool-grey">
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

export default Impressum;
