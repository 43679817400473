import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { MapContainer, GeoJSON, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const germanyBounds = [
  [55.5, 5.5],
  [46.5, 16.5],
];

function Home() {
  const [geojsonData, setGeojsonData] = useState(null);
  const [pressData, setPressData] = useState([]);
  const [selectedPress, setSelectedPress] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const markerRefs = useRef([]);

  useEffect(() => {
    fetch('/germany-states.json')
      .then((response) => response.json())
      .then((data) => setGeojsonData(data))
      .catch((error) => console.error('Error fetching GeoJSON:', error));

    fetch('/tempPoliceData.json')
      .then((response) => response.json())
      .then((data) => {
        setPressData(data);
      })
      .catch((error) => {
        console.error('Error fetching press data:', error);
        setPressData([]);
      });
  }, []);

  const pulsatingIcon = L.divIcon({
    className: 'pulsating-marker',
    iconSize: [12, 12],
  });

  const capitalizeName = (name) =>
    name.toLowerCase().replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());

  const geoJSONStyle = {
    color: '#B0B0B0', // Light grey outline color
    weight: 2,
    fillOpacity: 0.8,
    fillColor: '#EAE9EB', // Default fill color
  };

  const onEachFeature = (feature, layer) => {
    const stateName = feature.properties.name;
    layer.bindTooltip(stateName, {
      permanent: false,
      direction: 'center',
      className: 'state-tooltip',
    });

    layer.on({
      mouseover: (e) => {
        const layer = e.target;
        layer.setStyle({
          fillOpacity: 0.9,
          fillColor: '#D3D3D3', // Lighter grey on hover
        });
      },
      mouseout: (e) => {
        const layer = e.target;
        layer.setStyle({
          fillOpacity: 0.8,
          fillColor: '#EAE9EB', // Reset to default fill color
        });
      },
    });
  };

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate = currentDate.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const validPressData = pressData.filter(
    (release) =>
      typeof release.Latitude === 'number' &&
      typeof release.Longitude === 'number' &&
      !isNaN(release.Latitude) &&
      !isNaN(release.Longitude)
  );

  const CenteredPopup = () => {
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === 'Escape') {
          setSelectedPress(null);
        }
      };

      document.addEventListener('keydown', handleEsc);
      return () => document.removeEventListener('keydown', handleEsc);
    }, []);

    if (!selectedPress) return null;

    return (
      <div className="centered-popup" style={popupStyle}>
        <span className="popup-close-x" onClick={() => setSelectedPress(null)}>
          x
        </span>
        <strong>{capitalizeName(selectedPress.Location)}</strong>
        <br />
        <br />
        {truncateText(selectedPress['Final Title'], 50)}
        <br />
        <br />
        <span
          onClick={() => window.open(selectedPress.URL, '_blank')}
          style={{
            color: '#5B24B2',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Mehr lesen
        </span>
      </div>
    );
  };

  const popupStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
    zIndex: 1000,
    width: '220px',
    height: '170px',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>DeliktRadar.de - Schutz vor sexueller Gewalt</title>
        <meta
          name="description"
          content="Informiere dich über Schutzmaßnahmen und aktuelle Vorfälle im Zusammenhang mit sexueller Gewalt."
        />
      </Helmet>

      <h1
        className="title"
        style={{ color: '#5B24B2', marginBottom: '10px', marginTop: '1px' }}
      >
        Sexuelle Gewalt in Deutschland
      </h1>

      <p
        className="betrugsradar-text"
        style={{
          fontSize: '18px',
          textAlign: 'center',
          marginBottom: '5px',
        }}
      >
        <strong>Delikt-Radar:</strong>
        <span className="large-number">{validPressData.length}</span>{' '}
        {validPressData.length === 1 ? 'Vorfall' : 'Vorfälle'}
      </p>
      <p
        style={{
          fontSize: '16px',
          textAlign: 'center',
          color: '#4D4D4D',
          marginTop: '0px',
        }}
      >
        ({currentDate})
      </p>

      <div
        className="map-section"
        style={{ position: 'relative', marginBottom: '40px' }}
      >
        <MapContainer
          bounds={germanyBounds}
          maxBounds={germanyBounds}
          maxBoundsViscosity={1.0}
          style={{ height: '80vh', width: '100%' }}
          zoomControl
          dragging
          scrollWheelZoom={false}
          doubleClickZoom={false}
          touchZoom
        >
          {geojsonData && (
            <GeoJSON
              data={geojsonData}
              style={geoJSONStyle}
              onEachFeature={onEachFeature}
            />
          )}
          {validPressData.map((release, index) => (
            <Marker
              key={index}
              position={[release.Latitude, release.Longitude]}
              icon={pulsatingIcon}
              ref={(el) => (markerRefs.current[index] = el)}
              eventHandlers={{
                click: () => {
                  setSelectedPress(release);
                },
              }}
            />
          ))}
        </MapContainer>
      </div>

      {selectedPress && <CenteredPopup />}
      <div className="content-section">
        <hr style={{ marginTop: '40px' }} />
        <h2>Sexualisierte Gewalt und Prävention</h2>
        <p>
          Sexuelle Gewalt ist ein ernstes Problem in Deutschland. Viele
          Betroffene wissen nicht, wie sie sich schützen oder Hilfe finden
          können. Täter nutzen oft Manipulation, um ihre Opfer zu kontrollieren.
          Unsere interaktive Deutschlandkarte zeigt stets aktuelle Informationen
          zu Vorfällen – ein wichtiges Instrument, um präventiv handeln zu
          können.
        </p>

        <hr style={{ marginTop: '40px' }} />
        <h2>Wie funktioniert diese Seite?</h2>
        <p>
          Mit moderner KI-Technologie durchsuchen wir täglich Polizeimeldungen
          nach Begriffen wie Vergewaltigung, sexualisierte Gewalt und anderen.
          Unser Ziel: Transparenz schaffen und Schutz fördern.
        </p>

        <hr style={{ marginTop: '40px' }} />
        <h3>Was bedeutet es, wenn keine Vorfälle angezeigt werden?</h3>
        <p>
          Wenn keine Vorfälle angezeigt werden, gab es an diesem Tag keine
          polizeilichen Meldungen. Besonders am Wochenende kann das vorkommen.
          Bleiben Sie dennoch wachsam.
        </p>
      </div>

      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <h2>Aktuelle Vorfälle am {currentDate}</h2>
        <p style={{ fontSize: '16px', color: '#4D4D4D' }}>
          In der folgenden Tabelle siehst du die aktuellen Vorfälle.
        </p>
      </div>

      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <button
          onClick={() => setShowTable(!showTable)}
          style={{
            padding: '10px 20px',
            backgroundColor: '#5B24B2',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {showTable ? 'Tabelle verbergen' : 'Tabelle anzeigen'}
        </button>
      </div>

      {showTable && (
        <div
          className="table-section"
          style={{ textAlign: 'left', marginTop: '20px' }}
        >
          <h3 style={{ marginBottom: '10px', color: '#5B24B2' }}>
            Aktuelle Vorfälle am {currentDate}
          </h3>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              marginTop: '20px',
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: '#f2f2f2',
                  textAlign: 'left',
                  color: '#4D4D4D',
                }}
              >
                <th
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #ddd',
                    color: '#4D4D4D',
                  }}
                >
                  Stadt
                </th>
                <th
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #ddd',
                    color: '#4D4D4D',
                  }}
                >
                  Beschreibung
                </th>
                <th
                  style={{
                    padding: '12px',
                    borderBottom: '1px solid #ddd',
                    color: '#4D4D4D',
                  }}
                >
                  Link
                </th>
              </tr>
            </thead>
            <tbody>
              {pressData.map((release, idx) => (
                <tr key={idx} style={{ color: '#4D4D4D' }}>
                  <td
                    style={{
                      padding: '12px',
                      borderBottom: '1px solid #ddd',
                      color: '#4D4D4D',
                    }}
                  >
                    {capitalizeName(release.Location)}
                  </td>
                  <td
                    style={{
                      padding: '12px',
                      borderBottom: '1px solid #ddd',
                      color: '#4D4D4D',
                    }}
                  >
                    {truncateText(release.Description, 100)}
                  </td>
                  <td
                    style={{
                      padding: '12px',
                      borderBottom: '1px solid #ddd',
                      color: '#4D4D4D',
                    }}
                  >
                    <a
                      href={release.URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#5B24B2' }}
                    >
                      Mehr lesen...
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Home;
